import { memo } from 'react';
import loadable from '@loadable/component';

import CardInView from '@Components/CardInView';

import type { FC } from 'react';
import type { InlineBannerData } from '@Promo/typings';

const InlineBannerTemplate = loadable(() => import('@Promo/templates/InlineBannerTemplate'));
const MiniBannerTemplate = loadable(() => import('@Promo/templates/MiniBannerTemplate'));

export interface BannerItemProps {
  analyticsTitle: string;
  banner: InlineBannerData;
  index: number;
  categoryName?: string;
}

const BannerItem: FC<BannerItemProps> = (props) => {
  const { analyticsTitle, banner, categoryName, index } = props;

  return (
    <CardInView
      listTitle={analyticsTitle}
      card={{
        link: banner.link?.url,
        promotionName: banner.promotionName,
      }}
      position={index}
      cardType='promo'
      id={banner.header}
      targetPathname={banner.link?.url}
    >
      {(() => {
        switch (banner.template) {
          case 'mini':
            return <MiniBannerTemplate banner={banner} />;

          default:
            return <InlineBannerTemplate categoryName={categoryName} banner={banner} />;
        }
      })()}
    </CardInView>
  );
};

export default memo(BannerItem);
