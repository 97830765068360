import { memo, useCallback, useState } from 'react';
import { cn } from '@divlab/divanui';
import { InView } from 'react-intersection-observer';

import styles from './CatalogContent.module.css';

import type { RowData } from '@Components/ProductMixedCatalog';
import type { FC, HTMLAttributes } from 'react';

export interface CatalogContentProps extends HTMLAttributes<HTMLDivElement> {
  rows: RowData[];
  isInSpotlight?: boolean;
  onChangeVisibleRow?: (page: number) => void;
}

interface RowProps extends HTMLAttributes<HTMLDivElement> {
  row: RowData;
  className?: string;
  onChangeVisibleRow?: (page: number) => void;
}

const Row: FC<RowProps> = (props) => {
  const { row, onChangeVisibleRow, ...restProps } = props;

  const handleChange = useCallback(
    (inView: boolean) => {
      if (!inView || !row.page) return;

      onChangeVisibleRow(row.page);
    },
    [row.page, onChangeVisibleRow],
  );

  return (
    <div {...restProps}>
      <InView onChange={handleChange}>
        <>
          {row.elems.map((item) => {
            const landscapeOrientation = item.orientation === 'landscape';

            return (
              <div
                className={cn(styles.item, {
                  [styles.double]: item.double,
                  [styles.bannerRow]: item.bannerRow,
                  [styles.withPlaceholder]:
                    row.needPlaceholder && landscapeOrientation && !item.double,
                })}
                key={item.id}
              >
                {item.content}
              </div>
            );
          })}
        </>
      </InView>
    </div>
  );
};

const CatalogContent: FC<CatalogContentProps> = (props) => {
  const { rows, isInSpotlight, onChangeVisibleRow } = props;
  const [currentPage, setCurrentPage] = useState<number>(rows[0].page);

  const handleChangePage = useCallback(
    (page: number) => {
      if (page === currentPage) return;

      setCurrentPage(page);

      if (onChangeVisibleRow) onChangeVisibleRow(page);
    },
    [currentPage, onChangeVisibleRow],
  );

  return (
    <>
      {rows.map((row, rowIndex) => {
        return (
          <Row
            key={`row_${rowIndex}`}
            row={row}
            className={cn(styles.row, { [styles.isInSpotlight]: isInSpotlight })}
            onChangeVisibleRow={handleChangePage}
          />
        );
      })}
    </>
  );
};

export default memo(CatalogContent);
